var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-h4 font-weight-regular"},[_vm._v("User Details")])]),_c('v-col',{attrs:{"lg":"6"}},[_c('div',{staticClass:"px-5 pb-5 d-flex justify-center align-center"},[_c('v-btn',{staticStyle:{"width":"150px"},attrs:{"outlined":""},on:{"click":function () { return _vm.$router.back(); }}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" Back ")],1),_c('div',{staticClass:"px-3"},[_vm._v(" ")]),(_vm.canShowUserEdit)?_c('v-btn',{staticStyle:{"width":"150px"},attrs:{"color":"primary","to":{ name: 'user.edit', params: { id: _vm.userId } }}},[_vm._v(" Edit ")]):_vm._e()],1)])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"10","xl":"8"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[(_vm.userData)?_c('table',{staticClass:"user-table"},[_vm._l((_vm.items),function(item){return _c('tr',{key:item.name},[_c('td',{staticClass:"label-cell"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-left value-cell"},[_vm._v(" "+_vm._s(_vm.emptyChecker(item.value(_vm.userData)))+" ")])])}),(_vm.canShowBilling)?_c('tr',[_c('td',{staticClass:"label-cell"},[_vm._v("Billing")]),_c('td',{staticClass:"text-left value-cell"},[_c('v-btn',{attrs:{"to":{ name: 'billing.index', query: { userId: _vm.userId } }}},[_c('v-icon',[_vm._v("mdi-receipt")]),_vm._v(" NID OCR Billing Receipts ")],1)],1)]):_vm._e()],2):_c('div',{staticClass:"text-center grey--text py-15"},[_c('v-progress-circular',{attrs:{"size":"32","indeterminate":""}}),_c('div',{staticClass:"caption my-2"},[_vm._v("Loading User...")])],1)])],1)],1),(_vm.canShowNidOcrCardByUser)?_c('custom-expansion-panel',{attrs:{"title":"Successfull NID OCR Requests"}},[_c('DynamicListView',{attrs:{"api":_vm.nidOcrCardByUser,"schema":[
        { text: 'Requested At', value: 'createdAt', sortable: true },
        { text: 'Requested By', value: 'userId', sortable: true },
        { text: 'Nid', value: 'nid', sortable: true },
        { text: 'Name', value: 'nameEn', sortable: true },
        { text: 'Type', value: 'type', sortable: true },
        {
          text: 'More',
          value: 'more',
          sortable: true,
        } ],"actions":{
        more: function (item) {
          return {
            name: 'nid-ocr-card.single',
            params: {
              requestId: item.requestId,
            },
          };
        },
      }}})],1):_vm._e(),(_vm.canShowOcrRequestByUser)?_c('custom-expansion-panel',{attrs:{"title":"NID OCR Requests"}},[_c('DynamicListView',{attrs:{"api":_vm.ocrRequestByUser,"schema":[
        { text: 'Requested At', value: 'createdAt', sortable: true },
        { text: 'Requested By', value: 'requestUserId', sortable: true },
        { text: 'Type', value: 'type', sortable: true },
        { text: 'Content Type', value: 'mimeType', sortable: true },
        {
          text: 'More',
          value: 'more',
          sortable: true,
        } ],"actions":{
        more: function (item) {
          return {
            name: 'ocr-request.single',
            params: {
              md5sum: item.md5sum,
            },
          };
        },
      }}})],1):_vm._e(),(_vm.canShowParseRequestByUser)?_c('custom-expansion-panel',{attrs:{"title":"NID Parse Requests"}},[_c('DynamicListView',{attrs:{"api":_vm.parseRequestByUser,"schema":[
        { text: 'Requested At', value: 'createdAt', sortable: true },
        { text: 'Id', value: 'id', sortable: true },
        { text: 'Requested By', value: 'requestUserId', sortable: true },
        { text: 'Type', value: 'type', sortable: true },
        {
          text: 'More',
          value: 'more',
          sortable: true,
        } ],"actions":{
        more: function (item) {
          return {
            name: 'parse-request.single',
            params: {
              id: item.id,
            },
          };
        },
      }}})],1):_vm._e(),(_vm.canShowLivenessRequestByUser)?_c('custom-expansion-panel',{attrs:{"title":"Face Liveness Requests"}},[_c('DynamicListView',{attrs:{"api":_vm.livenessRequestByUser,"schema":[
        { text: 'Requested At', value: 'createdAt', sortable: true },
        { text: 'Request ID', value: 'requestId', sortable: true },
        { text: 'Label', value: 'label', sortable: true },
        { text: 'Model Name', value: 'modelName', sortable: true },
        { text: 'Model Version', value: 'modelVersion', sortable: true },
        { text: 'Confidence', value: 'confidence', sortable: true },
        {
          text: 'More',
          value: 'more',
          sortable: true,
        } ],"actions":{
        more: function (item) {
          return {
            name: 'liveness-request.single',
            params: {
              requestId: item.requestId,
            },
          };
        },
      }}})],1):_vm._e(),(_vm.canShowMatchRequestByUser)?_c('custom-expansion-panel',{attrs:{"title":"Face Match Requests"}},[_c('DynamicListView',{attrs:{"api":_vm.matchRequestByUser,"schema":[
        { text: 'Requested At', value: 'createdAt', sortable: true },
        { text: 'Request ID', value: 'requestId', sortable: true },
        { text: 'User', value: 'userId', sortable: true },
        { text: 'Label', value: 'label', sortable: true },
        { text: 'Confidence', value: 'confidence', sortable: true },
        {
          text: 'More',
          value: 'more',
          sortable: true,
        } ],"actions":{
        more: function (item) {
          return {
            name: 'match-request.single',
            params: {
              requestId: item.requestId,
            },
          };
        },
      }}})],1):_vm._e(),(_vm.canShowPorichoyRequestByUser)?_c('custom-expansion-panel',{attrs:{"title":"Porichoy Requests"}},[_c('DynamicListView',{attrs:{"api":_vm.porichoyRequestByUser,"schema":[
        { text: 'Requested At', value: 'createdAt', sortable: true },
        { text: 'Requested By', value: 'requestUserId', sortable: true },
        { text: 'NID', value: 'nid', sortable: true },
        { text: 'DOB', value: 'dateOfBirth', sortable: true },
        {
          text: 'More',
          value: 'more',
          sortable: true,
        } ],"actions":{
        more: function (item) {
          return {
            name: 'porichoy-request.single',
            params: {
              requestId: item.requestId,
            },
          };
        },
      }}})],1):_vm._e(),(_vm.canShowScraperRequestByUser)?_c('custom-expansion-panel',{attrs:{"title":"NID Scraper Requests"}},[_c('DynamicListView',{attrs:{"api":_vm.scraperRequestByUser,"schema":[
        { text: 'Requested At', value: 'createdAt', sortable: true },
        { text: 'Requested By', value: 'requestUserId', sortable: true },
        { text: 'NID', value: 'nid', sortable: true },
        { text: 'DOB', value: 'dateOfBirth', sortable: true },
        {
          text: 'More',
          value: 'more',
          sortable: true,
        } ],"actions":{
        more: function (item) {
          return {
            name: 'nid-scraper.single',
            params: {
              requestId: item.requestId,
            },
          };
        },
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }