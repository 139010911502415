<template>
  <v-row justify="center" class="text-center ma-0 pa-0">
    <v-col cols="12" class="ma-0 pa-0">
      <v-data-table
        :loading="loading"
        :search="null"
        :headers="schema"
        :items="data"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :server-items-length="totalItems"
        disable-sort
        item-key="id"
        class="elevation-0"
        id="match-table"
        :footer-props="footerProps"
        style="cursor: pointer"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.more`]="{ item, header }">
          <v-btn outlined :to="getActions('more', item)">
            {{ header.text }}
          </v-btn>
        </template>
        <template v-slot:[`item.userId`]="{ item }">
          <v-btn outlined :to="{ name: 'user.view', params: { id: item.userId } }">
            {{ item.userId }}
          </v-btn>
        </template>
        <template v-slot:[`item.requestUserId`]="{ item }">
          <v-btn
            v-if="item.requestUserId"
            outlined
            :to="{ name: 'user.view', params: { id: item.requestUserId } }"
          >
            {{ item.requestUserName || item.requestUserName }}
          </v-btn>
        </template>
        <template v-slot:[`item.confidence`]="{ item }">
          {{ Math.round(100 * item.confidence) }}%
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import sortBy from "lodash.sortby";

export default {
  name: "DynamicListView",
  components: {},
  props: {
    api: {
      required: true,
      type: String,
    },
    schema: {
      required: true,
      type: Array,
    },
    actions: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      page: 1,
      itemsPerPage: 5,
      totalItems: null,
      optionsLength: 6,
      footerProps: { "items-per-page-options": [5] },
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    page() {
      this.fetchData();
    },
    itemsPerPage() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const response = await this.$axios.get(this.api, {
          params: { page: this.page - 1, limit: this.itemsPerPage },
        });
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.totalItems = response.data.data.totalItems;
        this.$parent.$emit("totalUpdated", this.totalItems);
        this.data = sortBy(response.data.data.items, "createdAt").reverse();
      } catch (err) {
        console.error(err);
        // this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    getActions(name, item) {
      if (this.actions[name]) {
        return this.actions[name](item);
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
