<template>
  <v-container>
    <v-row justify="center" class="mt-5">
      <v-col cols="12" class="text-center">
        <h4 class="text-h4 font-weight-regular">User Details</h4>
      </v-col>
      <v-col lg="6">
        <div class="px-5 pb-5 d-flex justify-center align-center">
          <v-btn style="width: 150px" @click="() => $router.back()" outlined>
            <v-icon>mdi-chevron-left</v-icon>
            Back
          </v-btn>
          <div class="px-3">&nbsp;</div>
          <v-btn
            color="primary"
            style="width: 150px"
            :to="{ name: 'user.edit', params: { id: userId } }"
            v-if="canShowUserEdit"
          >
            Edit
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="10" xl="8">
        <v-card :loading="loading">
          <table class="user-table" v-if="userData">
            <tr v-for="item in items" :key="item.name">
              <td class="label-cell">{{ item.name }}</td>
              <td class="text-left value-cell">
                {{ emptyChecker(item.value(userData)) }}
              </td>
            </tr>
            <tr v-if="canShowBilling">
              <td class="label-cell">Billing</td>
              <td class="text-left value-cell">
                <v-btn :to="{ name: 'billing.index', query: { userId } }">
                  <v-icon>mdi-receipt</v-icon> NID OCR Billing Receipts
                </v-btn>
              </td>
            </tr>
          </table>
          <div class="text-center grey--text py-15" v-else>
            <v-progress-circular size="32" indeterminate />
            <div class="caption my-2">Loading User...</div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <custom-expansion-panel title="Successfull NID OCR Requests" v-if="canShowNidOcrCardByUser">
      <DynamicListView
        :api="nidOcrCardByUser"
        :schema="[
          { text: 'Requested At', value: 'createdAt', sortable: true },
          { text: 'Requested By', value: 'userId', sortable: true },
          { text: 'Nid', value: 'nid', sortable: true },
          { text: 'Name', value: 'nameEn', sortable: true },
          { text: 'Type', value: 'type', sortable: true },
          {
            text: 'More',
            value: 'more',
            sortable: true,
          },
        ]"
        :actions="{
          more: (item) => {
            return {
              name: 'nid-ocr-card.single',
              params: {
                requestId: item.requestId,
              },
            };
          },
        }"
      />
    </custom-expansion-panel>

    <custom-expansion-panel title="NID OCR Requests" v-if="canShowOcrRequestByUser">
      <DynamicListView
        :api="ocrRequestByUser"
        :schema="[
          { text: 'Requested At', value: 'createdAt', sortable: true },
          { text: 'Requested By', value: 'requestUserId', sortable: true },
          { text: 'Type', value: 'type', sortable: true },
          { text: 'Content Type', value: 'mimeType', sortable: true },
          {
            text: 'More',
            value: 'more',
            sortable: true,
          },
        ]"
        :actions="{
          more: (item) => {
            return {
              name: 'ocr-request.single',
              params: {
                md5sum: item.md5sum,
              },
            };
          },
        }"
      />
    </custom-expansion-panel>

    <custom-expansion-panel title="NID Parse Requests" v-if="canShowParseRequestByUser">
      <DynamicListView
        :api="parseRequestByUser"
        :schema="[
          { text: 'Requested At', value: 'createdAt', sortable: true },
          { text: 'Id', value: 'id', sortable: true },
          { text: 'Requested By', value: 'requestUserId', sortable: true },
          { text: 'Type', value: 'type', sortable: true },
          {
            text: 'More',
            value: 'more',
            sortable: true,
          },
        ]"
        :actions="{
          more: (item) => {
            return {
              name: 'parse-request.single',
              params: {
                id: item.id,
              },
            };
          },
        }"
      />
    </custom-expansion-panel>

    <custom-expansion-panel title="Face Liveness Requests" v-if="canShowLivenessRequestByUser">
      <DynamicListView
        :api="livenessRequestByUser"
        :schema="[
          { text: 'Requested At', value: 'createdAt', sortable: true },
          { text: 'Request ID', value: 'requestId', sortable: true },
          { text: 'Label', value: 'label', sortable: true },
          { text: 'Model Name', value: 'modelName', sortable: true },
          { text: 'Model Version', value: 'modelVersion', sortable: true },
          { text: 'Confidence', value: 'confidence', sortable: true },
          {
            text: 'More',
            value: 'more',
            sortable: true,
          },
        ]"
        :actions="{
          more: (item) => {
            return {
              name: 'liveness-request.single',
              params: {
                requestId: item.requestId,
              },
            };
          },
        }"
      />
    </custom-expansion-panel>

    <custom-expansion-panel title="Face Match Requests" v-if="canShowMatchRequestByUser">
      <DynamicListView
        :api="matchRequestByUser"
        :schema="[
          { text: 'Requested At', value: 'createdAt', sortable: true },
          { text: 'Request ID', value: 'requestId', sortable: true },
          { text: 'User', value: 'userId', sortable: true },
          { text: 'Label', value: 'label', sortable: true },
          { text: 'Confidence', value: 'confidence', sortable: true },
          {
            text: 'More',
            value: 'more',
            sortable: true,
          },
        ]"
        :actions="{
          more: (item) => {
            return {
              name: 'match-request.single',
              params: {
                requestId: item.requestId,
              },
            };
          },
        }"
      />
    </custom-expansion-panel>

    <!-- <custom-expansion-panel title="Image Request" v-if="canShowFaceRequestByUser">
      <DynamicListView
        :api="faceRequestByUser"
        :schema="[
          { text: 'Requested At', value: 'createdAt', sortable: true },
          { text: 'Request User Name', value: 'requestUserName', sortable: true },
          { text: 'Type', value: 'type', sortable: true },
          { text: 'Content Type', value: 'mimeType', sortable: true },
          {
            text: 'More',
            value: 'more',
            sortable: true,
          },
        ]"
        :actions="{
          more: (item) => {
            return {
              name: 'request-image.single',
              params: {
                md5sum: item.md5sum,
              },
            };
          },
        }"
      />
    </custom-expansion-panel> -->

    <custom-expansion-panel title="Porichoy Requests" v-if="canShowPorichoyRequestByUser">
      <DynamicListView
        :api="porichoyRequestByUser"
        :schema="[
          { text: 'Requested At', value: 'createdAt', sortable: true },
          { text: 'Requested By', value: 'requestUserId', sortable: true },
          { text: 'NID', value: 'nid', sortable: true },
          { text: 'DOB', value: 'dateOfBirth', sortable: true },
          {
            text: 'More',
            value: 'more',
            sortable: true,
          },
        ]"
        :actions="{
          more: (item) => {
            return {
              name: 'porichoy-request.single',
              params: {
                requestId: item.requestId,
              },
            };
          },
        }"
      />
    </custom-expansion-panel>

    <custom-expansion-panel title="NID Scraper Requests" v-if="canShowScraperRequestByUser">
      <DynamicListView
        :api="scraperRequestByUser"
        :schema="[
          { text: 'Requested At', value: 'createdAt', sortable: true },
          { text: 'Requested By', value: 'requestUserId', sortable: true },
          { text: 'NID', value: 'nid', sortable: true },
          { text: 'DOB', value: 'dateOfBirth', sortable: true },
          {
            text: 'More',
            value: 'more',
            sortable: true,
          },
        ]"
        :actions="{
          more: (item) => {
            return {
              name: 'nid-scraper.single',
              params: {
                requestId: item.requestId,
              },
            };
          },
        }"
      />
    </custom-expansion-panel>
  </v-container>
</template>

<script>
import * as api from "@/constants/api";
import DynamicListView from "@/views/components/User/DynamicListView";
import CustomExpansionPanel from "@/views/components/CustomExpansionPanel";

export default {
  name: "UserView",
  metaInfo: { title: "User Details" },
  components: { DynamicListView, CustomExpansionPanel },
  data: () => ({
    loading: false,
    userData: null,
    total: {},
  }),
  computed: {
    userId() {
      return Number(this.$route.params.id);
    },

    items: () => [
      {
        name: "Domain",
        value: (item) => item.domain,
      },
      {
        name: "User Id",
        value: (item) => item.id,
      },
      {
        name: "Role",
        value: (item) => item.role,
      },
      {
        name: "Username",
        value: (item) => item.username,
      },
      {
        name: "Full Name",
        value: (item) => item.fullName,
      },
      {
        name: "Email",
        value: (item) => item.email,
      },
      {
        name: "Disabled",
        value: (item) => (item.disabled ? "Yes" : "No"),
      },
      {
        name: "Locked",
        value: (item) => (item.locked ? "Yes" : "No"),
      },
    ],

    nidOcrCardByUser() {
      return `${api.NID_OCR_CARDS_BY_USER}?userId=${this.userId}`;
    },
    ocrRequestByUser() {
      return `${api.OCR_REQUESTS_BY_USER}?userId=${this.userId}`;
    },
    parseRequestByUser() {
      return `${api.PARSE_REQUESTS_BY_USER}?userId=${this.userId}`;
    },
    livenessRequestByUser() {
      return `${api.FACE_LIVENESS_REQUESTS_BY_USER}?userId=${this.userId}`;
    },
    matchRequestByUser() {
      return `${api.FACE_MATCH_REQUESTS_BY_USER}?userId=${this.userId}`;
    },
    faceRequestByUser() {
      return `${api.FACE_REQUEST_IMAGES_BY_USER}?userId=${this.userId}`;
    },
    porichoyRequestByUser() {
      return `${api.PORICHOY_REQUESTS_BY_USER}?userId=${this.userId}`;
    },
    scraperRequestByUser() {
      return `${api.NID_SCRAPER_REQUESTS_BY_USER}?userId=${this.userId}`;
    },
    canShowNidOcrCardByUser() {
      return this.verifyPrivilege(api.NID_OCR_CARDS_BY_USER);
    },
    canShowBilling() {
      return window.CBS_ONLY !== "true" && this.verifyPrivilege(api.NID_OCR_BILLING_LIST);
    },
    canShowOcrRequestByUser() {
      return this.verifyPrivilege(api.OCR_REQUESTS_BY_USER);
    },
    canShowParseRequestByUser() {
      return this.verifyPrivilege(api.PARSE_REQUESTS_BY_USER);
    },
    canShowLivenessRequestByUser() {
      return this.verifyPrivilege(api.FACE_LIVENESS_REQUESTS_BY_USER);
    },
    canShowMatchRequestByUser() {
      return this.verifyPrivilege(api.FACE_MATCH_REQUESTS_BY_USER);
    },
    canShowFaceRequestByUser() {
      return this.verifyPrivilege(api.FACE_REQUEST_IMAGES_BY_USER);
    },
    canShowPorichoyRequestByUser() {
      return this.verifyPrivilege(api.PORICHOY_REQUESTS_BY_USER);
    },
    canShowScraperRequestByUser() {
      return this.verifyPrivilege(api.NID_SCRAPER_REQUESTS_BY_USER);
    },
    canShowUserEdit() {
      return this.verifyPrivilege(api.USER_UPDATE);
    },
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
    async fetchUser() {
      try {
        this.loading = true;
        const response = await this.$axios.get(api.USER_GET, {
          params: { userId: this.userId },
        });
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.userData = response.data.data;
      } catch (err) {
        this.$iziToast.showError(err);
        this.$router.back();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.user-table {
  table-layout: fixed;
  padding: 10px;
  width: 100%;

  td {
    padding: 10px;
  }
  tr:not(:last-child) td {
    border-bottom: 1px solid #eee;
  }

  .label-cell {
    width: 50%;
    text-align: right;
    border-right: 1px solid #eee;
  }

  .value-cell {
    width: 50%;
    font-weight: 500;
    text-align: left;
  }
}
</style>
