<template>
  <v-row justify="center">
    <v-col cols="12" lg="10" xl="8">
      <v-card>
        <v-expansion-panels v-bind="$attrs">
          <v-expansion-panel>
            <v-expansion-panel-header v-if="title">
              {{ title }}
              <code v-if="typeof total === 'number'" class="sub-header mr-2 pa-1"
                >{{ total }}
              </code>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <event-listener @totalUpdated="totalUpdated">
                <div>
                  <slot />
                </div>
              </event-listener>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import EventListener from "@/views/components/EventListener";
export default {
  name: "CustomExpansionPanel",
  components: { EventListener },
  props: {
    title: {
      type: String,
      default: () => null,
      required: false,
    },
  },
  data() {
    return { total: null };
  },
  methods: {
    totalUpdated(total) {
      this.total = total;
    },
  },
};
</script>

<style scoped>
.sub-header {
  margin-left: 0.8rem;
  flex: 0 1 auto !important;
}
</style>
