var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"text-center ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"search":null,"headers":_vm.schema,"items":_vm.data,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"disable-sort":"","item-key":"id","id":"match-table","footer-props":_vm.footerProps},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.more",fn:function(ref){
var item = ref.item;
var header = ref.header;
return [_c('v-btn',{attrs:{"outlined":"","to":_vm.getActions('more', item)}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","to":{ name: 'user.view', params: { id: item.userId } }}},[_vm._v(" "+_vm._s(item.userId)+" ")])]}},{key:"item.requestUserId",fn:function(ref){
var item = ref.item;
return [(item.requestUserId)?_c('v-btn',{attrs:{"outlined":"","to":{ name: 'user.view', params: { id: item.requestUserId } }}},[_vm._v(" "+_vm._s(item.requestUserName || item.requestUserName)+" ")]):_vm._e()]}},{key:"item.confidence",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(100 * item.confidence))+"% ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }